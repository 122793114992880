<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row>
          <Drawer />
          <v-col
            cols="12"
            md="9"
            class="ml-auto pa-0"
          >
            <Header
              :commande="commande"
              :back-to="{name: 'Commande'}"
              :back-text="$t('retour-commande')"
              max-height="180px"
            >
              <template v-slot:title>
                <p class="white--text">
                  {{ $t('packing-list') + commande.n }}
                </p>
              </template>
              <template v-slot:subtitle>
                <p class="white--text">
                  {{ $t('descriptif-elements-composant-piscinelle',{type: commande.type_cmd === '8' ? $t('commande.type.petite-source') : $t('piscinelle')}) }}
                  <v-btn
                    class="v-btn"
                    :href="returnUrl()"
                    target="_blank"
                  >
                    {{ $t('voir-pdf') }}
                  </v-btn>
                </p>
              </template>
            </Header>
            <v-row class="ma-1 ml-0">
              <v-col
                cols="12"
                md="9"
              >
                <v-card
                  flat
                  justify="center"
                  color="transparent"
                >
                  <v-expansion-panels
                    multiple
                    class="packing-list"
                  >
                    <v-expansion-panel
                      v-for="(ligne,rowIndex) in commande.lignes"
                      :key="rowIndex"
                    >
                      <v-expansion-panel-header>{{ ligne.desig }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <template v-for="(nmc, nmcIndex) in ligne.nmc">
                          <v-divider :key="`divider-${nmcIndex}`" />
                          <NomenclatureCard
                            :key="`card-${nmcIndex}`"
                            :nmc="nmc"
                            @click="openCarousel(rowIndex, nmcIndex)"
                          />
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
                <template v-if="commande.lignes">
                  <DialogCarousel
                    v-for="(ligne,rowIndex) in commande.lignes"
                    :key="rowIndex"
                    v-model="ligne.show_nmc"
                    :items="ligne.nmc"
                    :packing-list="true"
                  >
                    <template v-slot:default="{ item }">
                      <v-card>
                        <v-card-title
                          class="pa-3 dialog-card-color white--text"
                        >
                          <span>{{ $t('composition-de') + " " + ligne.desig }}</span>
                          <v-spacer />
                          <a
                            v-if="isAdmin() && item.ref"
                            class="white--text text-decoration-underline text-body-2 ml-5"
                            target="_blank"
                            :href="extranetUrl + 'article/edit-articles-desc?ref='+item.ref"
                          >Modifier le descriptif</a>
                          <v-btn
                            icon
                            dark
                            @click="ligne.show_nmc = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text
                          class="pb-13"
                        >
                          <div class="text-h6 text-center my-3">
                            {{ item.desc ? item.desc.lang[$i18n.locale].libelle : item.libelle }}
                          </div>
                          <v-carousel
                            v-if="item.desc && item.desc.images && item.desc.images.length > 0"
                            class="ma-auto carousel-photos-nmc"
                            :hide-delimiters="item.desc.images.length < 2"
                            :show-arrows="item.desc.images.length > 1"
                          >
                            <v-carousel-item
                              v-for="(src,index) in item.desc.images"
                              :key="index"
                            >
                              <v-img
                                :src="src"
                                contain
                              />
                            </v-carousel-item>
                          </v-carousel>

                          <div class="mb-5 mt-3 text-center">
                            <span class="font-weight-bold">{{ $t('quantite') }}:</span> {{ item.qte }} <v-icon>mdi-circle-small</v-icon>
                            <span class="font-weight-bold">{{ $t('reference') }}:</span> {{ item.ref }}
                          </div>

                          <!-- eslint-disable vue/no-v-html -->
                          <div
                            v-if="item.desc"
                            v-html="$options.filters.packingListAddTutokey($options.filters.putEmailEndOfPiscinelleLinks($options.filters.allLinksToBlank(item.desc.lang[$i18n.locale].desc_riche),user.email))"
                          />
                          <!-- eslint-enable vue/no-v-html -->
                          <div
                            v-else
                            class="text-center"
                          >
                            {{ $t('pas-de-description') }}
                          </div>
                        </v-card-text>
                      </v-card>
                    </template>
                  </DialogCarousel>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NomenclatureCard from '@/components/nomenclature/Card'
import DialogCarousel from '@/components/DialogCarousel'
import Header from '@/components/Header'
import Drawer from '@/components/Drawer'

import { mapGetters } from 'vuex'

export default {
  name: 'PackingList',
  components: {
    DialogCarousel,
    NomenclatureCard,
    Header,
    Drawer
  },
  data () {
    return {
      extranetUrl: process.env.VUE_APP_EXTRANET_URL,
      commande: {}
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  mounted () {
    this.$api.get('/espace-client/packing-list/' + this.$route.params.id).then(res => {
      this.commande = res.data
    })
  },
  methods: {
    openCarousel (rowIndex, nmcIndex) {
      this.commande.lignes[rowIndex].show_nmc = nmcIndex
    },
    returnUrl () {
      return (`${process.env.VUE_APP_EXTRANET_URL}divers/packing-list-piscine?cm_id=${this.commande.n}&ct_id=${this.commande.client}`)
    }
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.carousel-photos-nmc {
  background:#f7f7f7;
  border: 1px solid #ccc;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: 245px !important;
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    height: 400px !important;
  }

  .v-carousel__controls{
    background: rgba(0, 0, 0, 0.1);
  }

  .v-image{
    height:100% !important;
  }
}

.packing-list{
  .v-expansion-panel-content__wrap {
    @media #{map-get($display-breakpoints, 'xs-only')} {
        padding: 0 2px!important;
    }
  }
}

.dialog-nmc{
  header{
    background: #ececec;
  }
}
</style>
